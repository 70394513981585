 import React from "react"

const BreadcrumbItem = ({ children, ...props }) => (

    <li className='breadcrumb-item' {...props}>{children}</li>
  )

  const BreadcrumbSeparator = ({ children, ...props }) => (
    <li className='breadcrumb-separator' {...props}>
      {children}
      <i className="fa-solid fa-chevron-right" ></i>
    </li>
  ) 

  const BreadcrumbActive = ({ children, ...props }) => (
    <li className='last' {...props}>{children}</li>
  )

const Breadcrumb = (props) => {
  let children = React.Children.toArray(props.children)

  children = children.map((child, index) => (
    <BreadcrumbItem key={`breadcrumb_item${index}`}>{child}</BreadcrumbItem>
  ))

  const lastIndex = children.length - 1

  children = children.reduce((acc, child, index) => {
    
    const notLast = index < lastIndex
    if (notLast) {
        acc.push(
           <li key={index} className="crumbs">{child}</li>,
            <BreadcrumbSeparator key={`breadcrumb_sep${index}`}></BreadcrumbSeparator>,
          )
        } else {
        // eslint-disable-next-line no-lone-blocks
        {acc.push(<BreadcrumbActive key={index} className="last">{child}</BreadcrumbActive>)}
    }
    return acc
  }, [])

  return <div className="breadcrumbs">{children}</div>
}
export default Breadcrumb