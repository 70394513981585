import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ImagePostal from "../assets/images/postalservice_193x84.svg";
import ImageLadyWithBox from "../assets/images/lady-holding-package_660X451.png";
import ImageMap from "../assets/images/print-guy-location_660x690.svg";
import ImageTodo from "../assets/images/todolist_310x310.svg";
import ImageModalTodo from "../assets/images/todolist_328x780.svg";
import ImageHyatt from "../assets/images/hyatt_310x310.svg";
import ImageHyattModal from "../assets/images/hyatt_830x796.svg";
import ImageQuince from "../assets/images/quince_310x310.svg";
import ImageQuinceModal from "../assets/images/quince_503x780.svg"
import ImageSafety from "../assets/images/safety_310x310.svg";
import ImageSafetyModal from "../assets/images/safety_830x780.svg";
import ImageSummer from "../assets/images/summertime_310x310.svg";
import ImageSummerModal from "../assets/images/summertime_275x370.svg";
import ImageMarriot from "../assets/images/concierge-bar-menu_310x310.svg";
import ImageMarriotModal from "../assets/images/concierge-bar-menu_321x780.svg";
import ImageOrnament from "../assets/images/ornament-premiere_310x310.svg";
import ImageOrnamentModal from "../assets/images/ornament-premiere_520x780.svg";
import ImageCobra from "../assets/images/cobra-alternatives_310x310.svg";
import ImageCobraModal from "../assets/images/cobra-alternatives_610x780.svg";
import ImageBass from "../assets/images/bass-team_310x310.svg";
import ImageBassModal from "../assets/images/bass-team_830x515.svg";
import ImageCrime from "../assets/images/crime-poster_310x310.svg";
import ImageCrimeModal from "../assets/images/crime-poster_520x780.svg";
import IconPrinter from "../assets/images/quality-prints_255x177.svg";
import IconMailBox from "../assets/images/mail-options_255x177.svg";
import IconPencil from "../assets/images/custom-designs_255x177.svg";
import IconDirectMail from "../assets/images/direct-mail_116x116.svg";
import IconManyBoxes from "../assets/images/mailing_116x116.svg";
import IconShipping from "../assets/images/shipping-packages_116x116.svg";
import IconPrinting from "../assets/images/printing_116x116.svg";
import IconChatBubble from "../assets/images/chat-help_150x150.svg";
import IconDiscount from "../assets/images/discount_150x150.svg";
import IconHouse from "../assets/images/not-for-profit_100x90.svg";
import IconBusiness from "../assets/images/small-business_100x90100x90.svg";
import IconCompany from "../assets/images/companies_100x90.svg";
import { ListIconBullet } from '../components/ListIcon';
import { Card, MultiUseCard } from "../components/Cards"; 
import Carousel from "../components/Carousel";
import {CarouselImageItem} from "../components/Carousel";
import { HeroBanner } from "../components/Banner";
import $ from "jquery";

const listBulletServices = [
    { icon: IconPrinting, header: 'PRINTING', bulletPoints: [{ bullet: 'Brochures' }, { bullet: 'Newsletters' }, { bullet: 'Door Hangers' }, { bullet: 'Postcards' }, { bullet: 'And much more!' }] },
    { icon: IconManyBoxes, header: 'MAILING', bulletPoints: [{ bullet: 'Bulk Mailing' }, { bullet: 'Certified Mailings' }, { bullet: 'Post Card Mailings' }, { bullet: 'First Class Mail' }, { bullet: 'Parcel' }, { bullet: 'Fold, Insert, and add postage to letters' }] },
    { icon: IconDirectMail, header: 'DIRECT MAIL', bulletPoints: [{ bullet: 'Personalized mailings to target the right customers' }, { bullet: 'Every Door Direct Mail <sup>&reg;</sup> '}] },
    { icon: IconShipping, header: 'SHIPPING PACKAGES', bulletPoints: [{ bullet: 'A variety of shipping options to suit your individual needs' }, { bullet: 'Convenience of printing & shipping in one stop' }, {
            bullet: 'Security of delivered packages'},{bullet:'No lines, no waiting'}, {bullet:'Tracking of packages', footerImage:{ImagePostal}}] }
]

export const Home = () => {

    const [Render, setRender] = useState();
    const [windowSize, setWindowSize] = useState(getWindowSize());
    //const dbResponse = useCoreContext();

    useEffect(() => {
        setRender(!Render)
    }, []);

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }


    return (
        <>
            <HeroBanner title="Professional Custom Printing Services" />
            <div className="bg-light pt-6 pb-5 px-3">
                <div className="container">
                    {/*<NotificationMessage />*/}
                    <div className="row align-items-center gap-4">
                        <div className="col-lg-6">
                            <h2>Bring Your Design Projects to Life Here</h2>
                            <p className="h5Font text-dark font-secondary">We offer free printing quotes of our services to get you the custom quality prints that you need.</p>
                            <div className="border border-info text-info font-secondary-bold col-lg-8">10% DISCOUNT APPLIED FOR NEW CUSTOMER</div>
                            <div className="row py-4">
                                <Link className="btn btn-primary rounded-pill col-lg-6" to="/">Start Free Quote</Link>
                            </div>
                        </div>
                        <img className="col-lg-5" src={ImageLadyWithBox} alt="lady holding boxes" />
                    </div>
                </div>
            </div>
            <div className="bg-white pt-6 pb-5" id="HowWeWork">
                <div className="container">
                    <div className="row p-1 align-items-center text-center">
                        <h2>How We Work</h2>
                        <p>At Once Printing is a full-service print shop that provides</p>
                        <div className="grid-column-3 px-5">
                            <Card size="" title="CUSTOM DESIGNS" headerstyle="text-info" imagestyle="card-image" image={IconPencil} />
                            <Card size="" title="QUALITY PRINTS" headerstyle="text-info" imagestyle="card-image" image={IconPrinter} />
                            <Card size="" title="MAIL OPTIONS" headerstyle="text-info" imagestyle="card-image" image={IconMailBox} />
                        </div>
                        <p>for small to medium-sized businesses in the area.<br/>
                            We would love to promote your business today.</p>
                    </div>
                </div>
            </div>
            <div className="bg-light pt-6 pb-5" id="OurServices">
                <div className="container">
                    <div className="row p-1 align-items-center text-center">
                        <h2>Explore All Of Our Services</h2>
                        <p>We offer a variety of services to take care of your printed communications from start to finish:</p>
                        <ListIconBullet iconSize="list-icon-lg" layout="grid-list-auto-2x2" dataObject={listBulletServices} />
                    </div>
                    <div className="grid-auto gap-3">
                        <MultiUseCard size="card-xlg" extrastyle="shadow" title="Undecided on where to start" paragraph="We are sure that At Once Printing can meet the needs of your business. If you are still undecided, ask us for a sample of our work, or a free quote." footerurl="/ContactUs" footertext="Contact Us Here" imgtop={IconChatBubble} />
                        <MultiUseCard size="card-xlg" extrastyle="shadow" title="First Time Discount" paragraph="Oh, and when you call us for your free quote, <b>remember to tell us that it is your first time calling</b>. We will be sure to <b>apply the 10% Off New Customer Discount</b>. We look forward to serving your businesses' print communication needs in the near future." imgtop={IconDiscount} />
                    </div>
                </div>
            </div>
            <div className="bg-our-work pt-6">  
                <div className="container" id="OurWork">
                    <h2 className="text-white text-center">Our Work</h2>
                    <p className="text-white text-center">Here are just a few of the many samples of our work.<br/> Please feel free to contact us for more samples or for a FREE quote.</p>
                    <Carousel>
                        {
                            windowSize.innerWidth < 767.98 ?(
                                <>
                                     <div className="carousel-item active">
                                        <div className="grid-carousel-items justify-content-center">
                                            <button type="button"  data-bs-toggle="modal" data-bs-target="#imageModal0">
                                                <CarouselImageItem id="0" image={ImageHyatt} imagemodal={ImageHyattModal}/>
                                            </button>               
                                            <button type="button"  data-bs-toggle="modal" data-bs-target="#imageModal1">
                                                <CarouselImageItem id="1" image={ImageQuince} imagemodal={ImageQuinceModal}/>
                                            </button>    
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="grid-carousel-items justify-content-start">
                                        <button type="button"  data-bs-toggle="modal" data-bs-target="#imageModal2">
                                            <CarouselImageItem id="2" image={ImageSafety} imagemodal={ImageSafetyModal}/>
                                        </button>
                                        <button type="button" data-bs-toggle="modal" data-bs-target="#imageModal3">
                                            <CarouselImageItem id="3" image={ImageTodo} imagemodal={ImageModalTodo}/>
                                        </button>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="grid-carousel-items justify-content-start">
                                            <button type="button" data-bs-toggle="modal" data-bs-target="#imageModal4">
                                                <CarouselImageItem id="4" image={ImageSummer} imagemodal={ImageSummerModal}/>
                                            </button>
                                            <button type="button" data-bs-toggle="modal" data-bs-target="#imageModal5">
                                                <CarouselImageItem id="5" image={ImageMarriot} imagemodal={ImageMarriotModal}/>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="grid-carousel-items justify-content-start">
                                            <button type="button" data-bs-toggle="modal" data-bs-target="#imageModal6">
                                                <CarouselImageItem id="6" image={ImageOrnament} imagemodal={ImageOrnamentModal}/>
                                            </button>
                                            <button type="button" data-bs-toggle="modal" data-bs-target="#imageModal7">
                                                <CarouselImageItem id="7" image={ImageCobra} imagemodal={ImageCobraModal}/>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="grid-carousel-items justify-content-start">
                                            <button type="button" data-bs-toggle="modal" data-bs-target="#imageModal8">
                                                <CarouselImageItem id="8" image={ImageBass} imagemodal={ImageBassModal}/>
                                            </button>
                                            <button type="button" data-bs-toggle="modal" data-bs-target="#imageModal9">
                                                <CarouselImageItem id="9" image={ImageCrime} imagemodal={ImageCrimeModal}/>
                                            </button>
                                        </div>
                                    </div>
                                </>
                            ):(
                                <>
                                    <div className="carousel-item active">
                                        <div className="grid-carousel-items justify-content-center">
                                            <button type="button"  data-bs-toggle="modal" data-bs-target="#imageModal0">
                                                <CarouselImageItem id="0" image={ImageHyatt} imagemodal={ImageHyattModal}/>
                                            </button>               
                                            <button type="button"  data-bs-toggle="modal" data-bs-target="#imageModal1">
                                                <CarouselImageItem id="1" image={ImageQuince} imagemodal={ImageQuinceModal}/>
                                            </button>               
                                            <button type="button"  data-bs-toggle="modal" data-bs-target="#imageModal2">
                                                <CarouselImageItem id="2" image={ImageSafety} imagemodal={ImageSafetyModal}/>
                                            </button>
                                            <button type="button" data-bs-toggle="modal" data-bs-target="#imageModal3">
                                                <CarouselImageItem id="3" image={ImageTodo} imagemodal={ImageModalTodo}/>
                                            </button>
                                            <button type="button" data-bs-toggle="modal" data-bs-target="#imageModal4">
                                                <CarouselImageItem id="4" image={ImageSummer} imagemodal={ImageSummerModal}/>
                                            </button>
                                            <button type="button" data-bs-toggle="modal" data-bs-target="#imageModal5">
                                                <CarouselImageItem id="5" image={ImageMarriot} imagemodal={ImageMarriotModal}/>
                                            </button>
                                            <button type="button" data-bs-toggle="modal" data-bs-target="#imageModal6">
                                                <CarouselImageItem id="6" image={ImageOrnament} imagemodal={ImageOrnamentModal}/>
                                            </button>
                                            <button type="button" data-bs-toggle="modal" data-bs-target="#imageModal7">
                                                <CarouselImageItem id="7" image={ImageCobra} imagemodal={ImageCobraModal}/>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="grid-carousel-items justify-content-start">
                                            <button type="button" data-bs-toggle="modal" data-bs-target="#imageModal8">
                                                <CarouselImageItem id="8" image={ImageBass} imagemodal={ImageBassModal}/>
                                            </button>
                                            <button type="button" data-bs-toggle="modal" data-bs-target="#imageModal9">
                                                <CarouselImageItem id="9" image={ImageCrime} imagemodal={ImageCrimeModal}/>
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        
                    </Carousel>
                </div>
            </div><br/><br/>
            <div className="bg-white pt-6 pb-5">
                <dib>
                    <div className="bg-dark text-white">
                        <div className="container" id="AboutUs">
                        <div className="grid-padding-lg">
                            <div>
                                <h2 className="text-white font-primary-medium">About us</h2><br/>
                                <p className="text-white">Our shop has been in Bedford Park since 1996 and is staffed with over 30 years of professional printing and graphics experience.<br/><br/> We believe you will find value in working with a neighborhood vendor like At Once Printing. Barring a high volume (100,000 or more) print job, At Once Printing can offer you a price as competitive as the big guys, but with a much quicker turnaround time. Plus, if you have a problem or question, we're very easy to get a hold of. Remember, we’re just down the street.</p>
                            </div>
                            <div className="available-space">
                                <img src={ImageMap} className="offcentered-image available-space"/>
                            </div>
                            </div>
                        </div>
                    </div>
                </dib>
            </div>
            <div className="bg-white py-4">
                <div className="container" id="OurClients">
                    <h2 className="text-center">Our Clients</h2>
                    <div className="grid-auto justify-content-center align-items-baseline px-5">
                        <div className="bg-light w-100 h-100 p-4">
                            <Card size="" title="NOT - FOR - PROFIT" headerstyle="text-secondary" image={IconHouse} />
                            <ul className="text-center text-dark font-secondary-bold p-1">
                                <li className="pt-3 pb-2">Regional/National Companies</li>
                                <li className="py-2">Churches</li>
                                <li className="py-2">Schools</li>
                                <li className="py-2">Townships</li>
                                <li className="py-2">Youth Sports Teams</li>
                            </ul>
                        </div>
                        <div className="bg-light w-100 h-100 p-4">
                            <Card size="" title="SMALL BUSINESS" headerstyle="text-secondary" image={IconBusiness} />
                            <ul className="text-center text-dark font-secondary-bold p-1">
                                <li className="pt-3 pb-2">Restaurants</li>
                                <li className="py-2">Taverns</li>
                                <li className="py-2">Country Clubs</li>
                                <li className="py-2">Doctors/ Dentists</li>
                            </ul>
                        </div>
                        <div className="bg-light w-100 h-100 p-4">
                            <Card size="" title="REGIONAL/NATIONAL COMPANIES" headerstyle="text-secondary" image={IconCompany} />
                            <ul className="text-center text-dark font-secondary-bold p-1">
                                <li className="pt-3 pb-2">Bradford Systems</li>
                                <li className="py-2">Insurance Carriers</li>
                                <li className="py-2">T.G.I Friday's</li>
                                <li className="py-2">Midway Hotel Center</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-primary py-4">
                <div className="container">
                    <div className="row align-items-center">
                        {
                            windowSize.innerWidth < 767.98 ?(
                                <>
                                    <div className="col-lg-9">
                                        <div className="row justify-content-center py-4">
                                            <div className="girl-computer-icon justify-content-center"><img /></div>
                                        </div>
                                            <div className="card-body">
                                                <div className="grid-row-1 py-3 text-center">
                                                    <div className="h3Font font-primary-semibold text-white">Connect With Us Today! </div>
                                                </div>
                                                <div className="grid-row-1 h4Font font-primary text-white pb-4">Get in touch and let us know how we can help.</div>
                                            </div>
                                    </div>
                                    <div className="row justify-content-center pb-4">
                                        <div className="col-10">
                                        <Link className="btn btn-outline-secondary rounded-pill w-100" to='/ContactUs'>Lets Start!</Link>
                                        </div>
                                    </div>
                                </>
                            ):(
                                <>
                                    <div className="col-lg-9">
                                        <div className="grid-col-2-auto-1fr align-items-center gap-3">
                                            <div className="girl-computer-icon"><img /></div>
                                            <div className="card-body">
                                                <div className="grid-row-1 text-start">
                                                    <div className="h4Font card-title text-white">Connect With Us Today!</div>
                                                </div>
                                                <div className="grid-row-1 text-start text-white">Get in touch and let us know how we can help.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <Link className="btn btn-outline-secondary rounded-pill w-100" to='/ContactUs'>Lets Start!</Link>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}