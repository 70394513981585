import React from 'react'
import { formReducer, INITIAL_STATE } from "./FormReducer";
import {useState, useEffect, useReducer} from "react"
import ContactUsForm from "./ContactUsForm";
import SuccessForm from "../SuccessForm.js";

const MasterContactUs = () => {
    const [State, Dispatch] = useReducer(formReducer, INITIAL_STATE);
    const [isSubmitted, setIsSubmitted] = useState(false);
    useEffect(() => {
        Dispatch({ type: "" });
    }, [State])

    const handleChange = (e) => {
        e.preventDefault();
        Dispatch({
            type: "CHANGE_INPUT",
            payload: { name: e.target.id, value: e.target.value }
        });
    }

    const submitForm = () => {
        return setIsSubmitted(true);
    }
    return (
        isSubmitted === false ? (
            <ContactUsForm submitForm={submitForm} handleChange={handleChange} state={State} dispatch={Dispatch} />
        ): isSubmitted === true && (
            <SuccessForm />
        )
    )
}

export default MasterContactUs