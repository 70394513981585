export const INITIAL_STATE = {
    FirstName: null,
    LastName: null,
    Subject: null,
    Message: null,
    ContactType: null,
    PhoneNumber: null,
    Email: null,
    FoundUs: null
};
/**
 * Purpose: Update the state and return the new version of the state
 * @param {any} state
 * @param {any} action
 */
export const formReducer = (state, action) => {
    switch (action.type) {
        case "CHANGE_INPUT":
            return {
                ...state,
                [action.payload.name]: action.payload.value
            };
        default: return state;
    }
}