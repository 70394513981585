import { Home } from "./pages/Home";
import ContactUs from "./pages/ContactUs";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },{
    path:'/ContactUs',
    element: <ContactUs/>
  }
];

export default AppRoutes;
