import React, { useState, useEffect }  from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

export const NavMenu = () => {
    const [ToggleIcon, setToggleIcon] = useState(false);
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
      function handleWindowResize() {
        setWindowSize(getWindowSize());
      }
  
      window.addEventListener('resize', handleWindowResize);
      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    }, []);

    function getWindowSize() {
      const {innerWidth, innerHeight} = window;
      return {innerWidth, innerHeight};
    }

    return (
      <header>
        {
           document.querySelectorAll('a').forEach(function(link) {
            link.addEventListener('click', function() {
              // Get the mobile navigation menu element
              var mobileNavbar = document.querySelector('.navbar-collapse.show')
              if (mobileNavbar) {
                // Remove the "show" class to hide the menu
                mobileNavbar.classList.remove('show')
                setToggleIcon(false);
              }
            })
          })
        }
        {
          document.querySelectorAll('.dropdown .dropdown-toggle').forEach(function(dropdown) {
            dropdown.addEventListener('click', function(e) {
                    e.preventDefault();
                    e.stopPropagation();
                    const parent = e.target.closest('.keep-open');
                    parent.classList.togggle('show');

                    e.target.classList.toggle('show');
                    const submenu = e.target.nextElementSibling;
                    submenu.classList.toggle('show');
                    
                    if(parent){
                      parent.querySelector('.dropdown-menu').classList.add('show');
                    }
              })
            })
        }       
        <nav className="navbar navbar-expand-lg bg-light">
          <div className="container-fluid p-0 ">
          <div className="nav-brand-container"> 
              <a className="nav-brand" href="/">
                <div className="nav-company-logo-size company-logo"/>
              </a>
              <div className="toggle-color toggle-section">
                <button className="navbar-toggler" type="button" onClick={() => {setToggleIcon(!ToggleIcon)}} data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                {
                  !ToggleIcon ? <span><i className="fa fa-bars nav-toggle-color"></i></span> : <span><i className="fa-regular fa-x nav-toggle-clicked-color"></i></span>
                }
              </button>
              </div>
            </div>
            <div className={`collapse navbar-collapse nav-routes container nav-routes-padding justify-content-end ${windowSize.innerWidth < 991.98 ? 'bg-white' : 'bg-dark' }`} id="navbarNavDropdown">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link" to="/">Home</Link>
                </li> 
                <li className="nav-item">
                  <Link className="nav-link" to="/?scrollTo=HowWeWork" >How We Work</Link>
                </li>               
                <li className="nav-item">
                  <Link className="nav-link" to="/?scrollTo=OurServices" >Services</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/?scrollTo=OurWork">Our Work</Link>
                </li> 
                <li className="nav-item">
                  <Link className="nav-link" to="/?scrollTo=AboutUs">About Us</Link>
                </li>                
                <li className="nav-item">
                  <Link className="nav-link" to="/?scrollTo=OurClients">Our Clients</Link>
                </li>                
                <li className="nav-item">
                  <Link className="nav-link" to="/ContactUs">Contact Us</Link>
                </li>
              </ul>
            </div>
          </div>
      </nav>
      </header>
    );
  }
