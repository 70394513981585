import React, {useEffect} from 'react'

const ImagePreloader = (props) => {
    useEffect(() => {
        
            const img = new Image();
            img.src = props.imageURL;
    },[props.imageURL])
  
    return null;
}

export default ImagePreloader