import React from 'react'
import Breadcrumb from '../components/Breadcrumbs';
import { Link } from 'react-router-dom';
import MasterContactUs from '../components/forms/contact-us/MasterContactUs';
import {MultiUseCard} from "../components/Cards";
import faxIcon from "../assets/images/fax_30x30.svg";
import locationIcon from "../assets/images/location_30x30.svg";
import phoneIcon from "../assets/images/phone_30x30.svg";
import {useCoreContext} from "../utils/CoreProvider";
import {CustomTooltip} from "../components/ToolTip";
import { Banner } from '../components/Banner';

const crumbs = [
    { to: '/', label: 'Home' },
    { to: '/ContactUs', label: 'Contact Us' }
]

const companyHours = [
    {day: 'Mon', hours: '8:30am - 5:00pm'},
    {day: 'Tue', hours: '8:30am - 5:00pm'},
    {day: 'Weds', hours: '8:30am - 5:00pm'},
    {day: 'Thur', hours: '8:30am - 5:00pm'},
    {day: 'Fri', hours: '8:30am - 5:00pm'},
    {day: 'Sat', hours: '8:30am - 5:00pm'},
    {day: 'Sun', hours: '8:30am - 5:00pm'},
]

const ContactUs = () => {

  const dbResponse = useCoreContext(); 
  
  
  return (
      <>
        <Banner title="Contact Us" image="banner-contact-us" />
        <div className="container pb-5">
            <Breadcrumb>
                {
                    crumbs.map(({to, label}) =>(           
                        <Link key={to} to={to}>{label}</Link>
                    ))
                }
            </Breadcrumb>
            <div className="row justify-content-center">
                <div className="col-lg-10">
                    <MasterContactUs />
                    <div className="grid-column-3">
                        <MultiUseCard size="" title="Phone" imgstart={phoneIcon} >
                            <div className="py-3">
                                <a className="btn btn-secondary btn-sm rounded-pill" href={`tel:${dbResponse.AppCore?.rawPhoneMain}`}>{dbResponse.AppCore?.phoneMain}</a>
                            </div><br/>
                            <div>
                                <CustomTooltip title="Hours of Operation" hoursObject={companyHours}>
                                    <button className="tooltip-container">Hours of Operation <i className="fa-solid fa-circle-question"/></button>
                                </CustomTooltip>
                            </div>
                        </MultiUseCard>                    
                        <MultiUseCard size="" title="Corporate Office" imgstart={locationIcon}>
                            <div className="pt-4 font-secondary h5Font">
                                {dbResponse.AppCore?.address}
                            </div>
                        </MultiUseCard>                  
                        <MultiUseCard size="" title="Fax" imgstart={faxIcon}>
                            <div className="pt-4 font-secondary h5Font">
                                {dbResponse.AppCore?.phoneSupport}
                            </div>
                        </MultiUseCard>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default ContactUs