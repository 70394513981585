import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './assets/styles/custom.css';

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";

// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.min.js";
import { CoreProvider } from './utils/CoreProvider';

function App() {

    const ScrollToSection = () => {
        const location = useLocation();

        useEffect(() => {
            const params = new URLSearchParams(location.search);
            const scrollTo = params.get('scrollTo');

            if (scrollTo) {
                const element = document.getElementById(scrollTo);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }, [location]);

        return null;
    }

    return (
        <CoreProvider>
            <ScrollToSection />
            <Layout>
                <Routes>
                    {AppRoutes.map((route, index) => {
                        const { element, ...rest } = route;
                        return <Route key={index} {...rest} element={element} />;
                    })}
                </Routes>
            </Layout>
        </CoreProvider>
    );
}

export default App;