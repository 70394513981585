import React,{useState, useEffect} from "react";

/**
 * @param children  
 * @note this component is dependent on whatever gets nested inside its html tag {children} works in correlation with 
 *       CarouselModal
 */
const Carousel = (props) => {
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }
    return (
        <>
            <div id="carouselExampleControls" className="carousel carousel-dark slide" data-bs-ride="carousel">
            <div className="carousel-indicators">
                {
                    windowSize.innerWidth < 767.98 ?(
                    <>

                        <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to="0" className="active"></button>
                        <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to="1"></button>
                        <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to="2"></button>
                        <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to="3"></button>
                        <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to="4"></button>
                    </> 
                    ): (
                        <>
                          <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to="0" className="active"></button>
                          <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to="1"></button>
                        </>
                    )
                }
            </div>
            <div className="carousel-inner">
                {props.children}
            </div>
                {/* <button className="carousel-control-prev justify-content-start" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next justify-content-end" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button> */}
            </div>
        </>
    );
};

export default Carousel;

/**
 * @note this is what gives the modal effect
 * @param id - MUST be unique this is usually a number
 * @param imagemodal - this is what gets displayed as the items image
 */
export const CarouselModal = (props) => {
    return (
        <>
            <div className="modal fade" id={`imageModal${props.id}`} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                   <div className="d-flex justify-content-end">
                        <button type="button" className="fa-solid fa-x fa-close-btn" data-bs-dismiss="modal" aria-label="Close"></button>
                   </div>
                    <div className="modal-content">
                        {/* <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div> */}
                        <div className="modal-body">
                            <img className="w-100 h-100"src={props.imagemodal} />
                        </div>
                        
                        {/*<div className="modal-footer">*/}
                        {/*    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>*/}
                        {/*    <button type="button" className="btn btn-primary">Save changes</button>*/}
                        {/*</div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

/**
 * @note this is NOT the modal but what gets displayed as an item in the carousel slide
 * @param id - MUST be unique this is usually a number
 * @param imagemodal - this is what gets displayed as the items image
 */
export const CarouselImageItem = (props) => {
    return (
        <>
            <div className="carousel-image-item">
                <img data-target="#carouselImage" src={props.image} alt="..." />
            </div>
            <CarouselModal id={props.id} imagemodal={props.imagemodal}/>
        </>
    )
}

/**
 * @note If wanted to use a card with text instead of a popup modal effect use this
 * @param id - MUST be unique this is usually a number
 * @param title
 * @param paragraph
 * @param btnpath
 * @param btntext
 */
export const CarouselItem = (props) => {
    return (
        <>
            <div className="card w-100" id={`modal${props.id}`}>
                <img src={props.image} className="card-img-top" alt="..." />
                <div className="card-body">
                    <h5 className="card-title">{props.title}</h5>
                    {props.paragraph && <p className="card-text">{props.paragraph}</p>}
                    <a href={props.btnpath} className="btn btn-primary">
                        {props.btntext}
                    </a>
                </div>
            </div>
        </>
    )
}

