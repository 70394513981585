import React, {useState} from 'react'

export const CustomTooltip = ({ title , hoursObject, children }) => {
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const [clickedTooltip, setClickedToolTip] = useState(false);
    const showTooltip = () => {
      setIsTooltipVisible(true);
    };
  
    const hideTooltip = () => {
      setIsTooltipVisible(false);
    };
  
    const toolTipClicked = () => {
      setClickedToolTip(!clickedTooltip);
    } 

    const focusOut = () => {
      setClickedToolTip(false);
    }
    return (
      <div className="custom-tooltip-container"
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
        onClick={toolTipClicked}
        onBlur={focusOut}
      >
        {children}
        {(isTooltipVisible || clickedTooltip) && (
              <div className="tooltip-content">
                <h6>{title}</h6>
                <div className="tagline pb-1">Central Time (CT)</div>
                <div>
                {
                 hoursObject.map((data) => {
                  return (    
                    <div className="grid-tooltip">
                      <div className="text-start">{data.day}</div>
                      <div>{data.hours}</div>
                    </div>
                    )
                  })
                }
                </div>
              </div>
            )
        }
      </div>
    );
  };