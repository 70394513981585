import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer>
        <div className="footer-primary">
            <div className="container">
                <div className="footer-layout"></div>
            </div>
        </div>
        <div className="footer-secondary">
            <div className="container">
                <div className="grid-footer-logo">
                    <div className='footer-company-logo-size company-logo'/>
                    <p> All text and images are copyright &copy; {(new Date().getFullYear())} At Once Printing and may not be used without permission. All rights reserved.</p>
                </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer