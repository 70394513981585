import { createContext, useContext, useState, useEffect } from 'react';
import $ from "jquery";


const CoreContext = createContext(null);

export const CoreProvider = ({children}) => {
    const [NotificationMessage, setNotificationMessage] = useState(null);
    const [AppCore, setAppCore] = useState(null);

    useEffect(() => {
        $.ajax({
            type: "POST",
            url: "/AppConfig/GetAppCore",
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (result) {
                setAppCore(result.value);
            },
            failure: function (response) {
                console.log(response);
            },
        });
        console.log("hit")
        checkNotification();
    }, []);
    const checkNotification = () => {

        $.ajax({
            type: "POST",
            url: "/AppConfig/GetNotificationMessage",
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (result) {
                setNotificationMessage(result.response.messageHTML);
            },
            failure: function (response) {
                console.log(response);
            },
        });
    }

    return <CoreContext.Provider value={{NotificationMessage, AppCore}}>{children}</CoreContext.Provider>
}

export const useCoreContext = () =>{
    return useContext(CoreContext)
}