export default function validateForm(info) {
    let errors = [];

    // For text input
    var required =  document.querySelectorAll("[name='requiredQuestion']");
    required.forEach((elm,index) => {
        if(elm?.value?.length !== 0){
            elm.style.backgroundColor = "white";
            elm.style.border ="1px solid #979797";
        }else if ((elm.value?.length === 0) || (info.ContactType === 'Phone' && info.Phone.length === 0) || (info.ContactType === 'Email' && info.Email.length === 0)){
            errors.push(elm)
            elm.style.backgroundColor = "white";
            elm.style.border ="2px solid #B22727";        
        }else if((info.ContactType === 'Email' && info.Phone.length === 0) || (info.ContactType === 'Phone' && info.Email.length === 0)){
            errors.push(elm)
            elm.style.backgroundColor = "white";
            elm.style.border ="1px solid #979797"; 
        }
    })

    // For Select Inputs
    var requiredSelect =  document.querySelectorAll("[id='Subject']");
    requiredSelect.forEach((elm, index) => {
        if (info.Subject === null || info.ContactType === null) {
            errors.push(elm);
            elm.style.backgroundColor = "white";
            elm.style.border ="2px solid #B22727";
        }else{
            elm.style.backgroundColor = "white";
            elm.style.border ="1px solid #979797";
        }
    })    
    
    // For Select Inputs
    var requiredSelect =  document.querySelectorAll("[id='ContactType']");
    requiredSelect.forEach((elm, index) => {
        if (info.Subject === null || info.ContactType === null) {
            errors.push(elm);
            elm.style.backgroundColor = "white";
            elm.style.border ="2px solid #B22727";
        }else{
            elm.style.backgroundColor = "white";
            elm.style.border ="1px solid #979797";
        }
    })    

    // For Select Inputs
    var requiredSelectFoundUs =  document.querySelectorAll("[id='FoundUs']");
    requiredSelectFoundUs.forEach((elm, index) => {
        if (info.FoundUs === null || info.FoundUs === "") {
            errors.push(elm);
            elm.style.backgroundColor = "white";
            elm.style.border ="2px solid #B22727";
        }else{
            elm.style.backgroundColor = "white";
            elm.style.border ="1px solid #979797";
        }
    })

    // Conditional Required - they are dependent on user response
    var dependentPhone =  document.querySelectorAll("[id='PhoneNumber']");
    var dependentEmail =  document.querySelectorAll("[id='Email']");

    dependentPhone.forEach((elm,index) => {
        if((info.PhoneNumber === null && info.ContactType === null) || (info.ContactType === "phone" && info.PhoneNumber === null)){
                    errors.push(elm)
                    elm.style.backgroundColor = "white";
                    elm.style.border ="2px solid #B22727";    
        }else{
                    elm.style.backgroundColor = "white";
                    elm.style.border ="1px solid #979797"; 
        }
    })

    dependentEmail.forEach((elm,index) => {
                if((info.Email === null && info.ContactType === null) || (info.ContactType === "Email" && info.Email === null)){
                    errors.push(elm)
                    elm.style.backgroundColor = "white";
                    elm.style.border ="2px solid #B22727";    
                }else{
                    elm.style.backgroundColor = "white";
                    elm.style.border ="1px solid #979797"; 
                }
    })

    return(errors.length)  
}